import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import "swiper/swiper-bundle.css"
import styles from "./work.module.css"
import SEO from "../components/seo"
import Typo from "../components/typo"
import { Link, graphql } from "gatsby"
import { Swiper, Navigation } from "swiper"
import IconArrowLeft from "../images/icon-arrow-left.svg"
import IconArrowRight from "../images/icon-arrow-right.svg"

Swiper.use([Navigation])

const Item = ({ slug, title, client, tag, banner }) => {
  return (
    <Link className={styles.item} to={`/work/${slug}`}>
      <div className={styles.item__image}>
        <img src={banner && banner.file ? banner.file.url : ``} alt="background" />
      </div>
      <div className={styles.item__text}>
        <Typo type="text600" component="div" className={styles.item__tag}>{tag ? tag.title : ``}</Typo>
        <Typo type="title" component="h3" className={styles.item__title}>{title}</Typo>
        <Typo type ="text" component="div" className={styles.item__client}>{client}</Typo>
      </div>
    </Link>
  )
}

const Work = ({ data }) => {
  const slider = useRef()
  const sliderEl = useRef()
  const prevEl = useRef()
  const nextEl = useRef()

  useEffect(() => {
    const createSlider = () => {
      if (!slider.current) {
        slider.current = new Swiper(sliderEl.current, {
          slidesPerView: 3,
          slidesPerGroup: 3,
          navigation: {
            nextEl: nextEl.current,
            prevEl: prevEl.current,
          },
        })
      }
    }

    const destroySlider = () => {
      if (slider.current) {
        slider.current.destroy()
        slider.current = null
      }
    }

    const handler = () => {
      if (window.innerWidth < 1024) {
        destroySlider()
      } else {
        createSlider()
      }
    }

    handler()
    window.addEventListener("resize", handler)

    return () => {
      destroySlider()
      window.removeEventListener("resize", handler)
    }
  }, [])

  return (
    <Layout fixedHeader>
      <SEO title="Work" />

      <div className={styles.root}>
        <div ref={sliderEl} className="swiper-container">
          <div className="swiper-wrapper">
            {data.allContentfulProject.edges.map(({ node }) => (
              <div className="swiper-slide" key={node.id}>
                <Item {...node} />
              </div>
            ))}
          </div>

          <div className="swiper-button-prev" ref={prevEl}>
            <Typo component="span">Prev</Typo>
            <IconArrowLeft />
          </div>
          <div className="swiper-button-next" ref={nextEl}>
            <IconArrowRight />
            <Typo component="span">Next</Typo>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Work

export const query = graphql`
  query MyQuery {
    allContentfulProject(sort: { fields: order, order: DESC }) {
      edges {
        node {
          id
          client
          slug
          title
          tag {
            title
          }
          banner {
            file {
              url
            }
          }
        }
      }
    }
  }
`